// React
import { useEffect, useState } from "react";

// Hooks and Redux
import { useAppSelector } from "../../../../redux/hooks";

// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams, enUS } from "@mui/x-data-grid";

// Utils
import { apiUrl } from "../../../../Constants";
import { thTHGrid } from "../../../../@types/DataGridLocaleText";
import { getAllActivePrefix } from "../../../../services/MasterDataService";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type Job = {
    id: string;
    job_id: string;
    job_name: string;
    num_openings: number;
    site: {
        site_id: string;
        site_name: string;
    };
    company: string;
    start_date: Date;
    end_date: Date;
    is_active: boolean;
}

interface Religion {
    [key: string]: string;
}
const RELIGION: Religion = {
    BDH: 'Buddhism',
    CHR: 'Christ',
    HND: 'Hindu',
    ISL: 'Islamic',
    NON: 'None Specify',
    SIK: 'Sikh',
};

interface Nationality {
    [key: string]: string;
}
const NATIONALITY: Nationality = {
    KHM: 'Cambodia',
    LAO: "Lao People's Democratic Republic",
    MMR: 'Myanmar',
    THA: 'Thailand',
};

interface MaritalStatus {
    [key: string]: string;
}
const MARITAL_STATUS: MaritalStatus = {
    D: 'Divorced',
    E: 'Seperated',
    M: 'Married',
    S: 'Single',
    W: 'Widowed',
};
interface Prefix {
    [key: string]: string;
  }
  
  const  PREFIX: Prefix = {
    Miss: 'Miss',
    Mr: 'Mr.',
    Mrs: 'Mrs.',
  }
  

const ReviewInfo = () => {
    const { t, i18n } = useTranslation();
    const { personalInfo, contactInfo, legalAddressInfo, mailingAddressInfo, jobInfo, educationInfo } = useAppSelector(state => state.jobapp);
    
    const monthTH: string[] = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    const monthEN: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    function changeFormat(date: Date) {
        const parse_date: Date = new Date(date)
        let formatted_date: string = "";
        i18n.language === 'th' ?
            formatted_date = parse_date.getDate() + ' ' + monthTH[parse_date.getMonth()] + ' ' + (parse_date.getFullYear() + 543) :
            formatted_date = parse_date.getDate() + ' ' + monthEN[parse_date.getMonth()] + ' ' + parse_date.getFullYear()
        return (formatted_date)
    }

    const [selectedPosition, setSelectedPosition] = useState<Job[]>([]);
    const [prefixList, setPrefixList] = useState<any>([]);

    useEffect(() => {
        const fetchActivePrefix = async () => {
          try {
              const response = await getAllActivePrefix();
      
              if (response.data?.success) {
                  setPrefixList(response.data?.marital_statuses);
              }
          } catch (error) {
              console.log(error);
          }
      }
      
          fetchActivePrefix();
      }, []);
    useEffect(() => {
        const position = JSON.parse(sessionStorage.getItem('selected_position') || '[]');

        const url = `${apiUrl}/api/jobs/getmanyjobs`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ jobs: position })
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {

                data.jobs.sort((a:any,b:any) => (dayjs(a.start_date).isAfter(dayjs(b.start_date)) ? 1 : -1))
                setSelectedPosition(data.jobs);
            });
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'job_name',
            headerName: `${t('Position')}`,
            width: 250,
        },
        {
            field: 'company',
            headerName: `${t('Company')}`,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.company
        },
        {
            field: 'date',
            headerName: `${t('Application period')}`,
            width: 300,
            valueGetter: (params: GridValueGetterParams) =>
                `${changeFormat(params.row.start_date || '')} - ${changeFormat(params.row.end_date || '')}`,
        },
        {
            field: 'num_openings',
            headerName: `${t('Job opening')}`,
            width: 120,
        },
    ];

    const mapPrefix = (prefix: string, lang:string) => {

        const data: any = prefix && prefixList.find((item:any) =>  prefix === item.external_code )!;
       
        const defaultPrefix = data && lang === 'TH' ? 
        data.label
        : prefix === 'Mr' || prefix === 'Miss' || prefix === 'Mrs' ?
        `${PREFIX[prefix!]} ` 
        : ''
    
      return defaultPrefix
    }

    return <div>
        {/* Personal Information */}

        <Grid container spacing={1} sx={{ mt: { xs: 3, sm: 6 }, ml: { xs: 1, sm: 8 } }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {t('Personal Information')}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Full name TH')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                {mapPrefix(personalInfo.prefix, 'TH')}
                {personalInfo.first_name_th} {personalInfo.last_name_th} 
                    {/* {personalInfo.prefix === 'Mr' ? 'นาย' : personalInfo.prefix === 'Mrs' ? 'นาง' : personalInfo.prefix === 'Miss' ? 'นางสาว' : null} {personalInfo.first_name_th} {personalInfo.last_name_th} */}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Full name EN')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                {mapPrefix(personalInfo.prefix, 'EN')}
                 {/* {personalInfo.prefix === 'Mr' ? 'Mr.' : personalInfo.prefix === 'Mrs' ? 'Mrs.' : personalInfo.prefix === 'Miss' ? 'Miss' : null} */}
                  {personalInfo.first_name_en || '-'} {personalInfo.last_name_en || '-'}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Gender')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {
                        i18n.language === 'th' ?
                            personalInfo.gender === 'M' ? 'ชาย' : 'หญิง' :
                            i18n.language === 'en' ?
                                personalInfo.gender === 'M' ? 'Male' : 'Female' : null
                    }
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Date of birth')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {changeFormat(personalInfo.date_of_birth!)}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('ID card number')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {personalInfo.id_card_number}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Nationality')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {t(NATIONALITY[personalInfo?.nationality!])}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Religion')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {t(RELIGION[personalInfo?.religion!])}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('MaritalStatus')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {t(MARITAL_STATUS[personalInfo?.marital_status!])}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Weight')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {personalInfo.weight} {t('kg')}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Height')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {personalInfo.height} {t('cm')}
                </Typography>
            </Grid>

            {/* Contact Information */}

            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Contact Information')}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Phone Number')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.telephone}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Email')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.email ? contactInfo.email : '-'}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Line ID')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.line_id ? contactInfo.line_id : '-'}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Person to notify in case of emergency')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.emergency_name ? contactInfo.emergency_name : '-'}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Phone number of person to call in case of emergency')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.emergency_phone ? contactInfo.emergency_phone : '-'}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Relationship')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {contactInfo.emergency_relation ? contactInfo.emergency_relation : '-'}
                </Typography>
            </Grid>

            {/* Education Information */}
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Education Information')}
                </Typography>
            </Grid>
            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Education Level')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {educationInfo.education_level ? educationInfo.education_level : '-'}
                </Typography>
            </Grid>
            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('School')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {educationInfo.education_school ? educationInfo.education_school : '-'}
                </Typography>
            </Grid>

            {/* Military Status */}
            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Military Status')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9} sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                    {personalInfo.military_status ? personalInfo.military_status : '-'}
                </Typography>
            </Grid>

            {/* Work Experience */}
            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Work Experience')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1">
                    {personalInfo.work_experience ? personalInfo.work_experience : '-'}
                </Typography>
            </Grid>

            {/* Permanent Address */}
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Legal Address')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1">
                    {`${t('House No.')} ${legalAddressInfo.address_house_number}, ${t('Moo')} ${legalAddressInfo.address_moo ? legalAddressInfo.address_moo : '-'}, ${t('Lane')} ${legalAddressInfo.address_lane ? legalAddressInfo.address_lane : '-'}, ${t('Street')} ${legalAddressInfo.address_street ? legalAddressInfo.address_street : '-'},`}
                </Typography>
                <Typography variant="subtitle1">
                    {`${t('Sub District')} ${legalAddressInfo.address_sub_district?.name_th}, ${t('District')} ${legalAddressInfo.address_district?.name_th}, ${t('Province')} ${legalAddressInfo.address_province?.label_th}, ${t('Postal Code')} ${legalAddressInfo.address_postal_code}`}
                </Typography>
            </Grid>

            {/* Mailing Address */}
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Mailing Address')}
                </Typography>
            </Grid>
            {
                mailingAddressInfo.same_as_legal_address ?
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1">
                            {t('Same as legal address')}
                        </Typography>
                    </Grid>
                    :
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1">
                            {`${t('House No.')} ${mailingAddressInfo.address_house_number}, ${t('Moo')} ${mailingAddressInfo.address_moo ? mailingAddressInfo.address_moo : '-'}, ${t('Lane')} ${mailingAddressInfo.address_lane ? mailingAddressInfo.address_lane : '-'}, ${t('Street')} ${mailingAddressInfo.address_street ? mailingAddressInfo.address_street : '-'},`}
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${t('Sub District')} ${mailingAddressInfo.address_sub_district?.name_th}, ${t('District')} ${mailingAddressInfo.address_district?.name_th}, ${t('Province')} ${mailingAddressInfo.address_province?.label_th}, ${t('Postal Code')} ${mailingAddressInfo.address_postal_code}`}
                        </Typography>
                    </Grid>
            }

            {/* Job Position */}
            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    {t('Job Application')}
                </Typography>
            </Grid>

            <Grid item xs={5} sm={3}>
                <Typography variant="subtitle1">
                    {t('Start date of work')}
                </Typography>
            </Grid>
            <Grid item xs={7} sm={9}>
                <Typography variant="subtitle1">
                    {changeFormat(jobInfo.start_date_of_work!)}
                </Typography>
            </Grid>

            <Box display='inline-grid' sx={{ width: '100%', mt: 2, mr: { xs: 3, sm: 10 } }}>
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={selectedPosition}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 30, 40, 50]}
                    getRowHeight={() => 50}
                    disableRowSelectionOnClick
                    sx={{
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        fontSize: 14,
                    }}
                    localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                />
            </Box>

        </Grid>
    </div>;
};

export default ReviewInfo;
